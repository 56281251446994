import { useMutation } from "@tanstack/react-query";
import {
  copyCardToCollection,
  copyChapterToPlaybook,
  copyCollectionToChapter,
} from "./copy-content.api";
import { useDispatch } from "react-redux";
import ContentAction from "../../../redux/stores/content/content.action";
import ContentTypesEnum from "../../../enums/content-types.enum";

export const useCopyChapterToPlaybook = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: ({
      chapterUid,
      playbookUids,
    }: {
      chapterUid: string;
      playbookUids: string[];
    }) =>
      Promise.all(
        playbookUids.map((playbookUid) =>
          copyChapterToPlaybook({
            playbookUid,
            chapterUid: chapterUid,
          }),
        ),
      ),
    onSuccess: () => {
      dispatch(ContentAction.getContent(ContentTypesEnum.CHAPTER));
      dispatch(ContentAction.getContent(ContentTypesEnum.COLLECTION));
      dispatch(ContentAction.getContent(ContentTypesEnum.CARD));
    },
  });
};

export const useCopyCollectionToChapter = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: ({
      chapterUids,
      collectionUid,
    }: {
      collectionUid: string;
      chapterUids: string[];
    }) =>
      Promise.all(
        chapterUids.map((chapterUid) =>
          copyCollectionToChapter({
            collectionUid: collectionUid,
            chapterUid,
          }),
        ),
      ),
    onSuccess: () => {
      dispatch(ContentAction.getContent(ContentTypesEnum.COLLECTION));
      dispatch(ContentAction.getContent(ContentTypesEnum.CARD));
    },
  });
};

export const useCopyCardToCollection = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: ({
      cardUid,
      collectionUids,
    }: {
      cardUid: string;
      collectionUids: string[];
    }) =>
      Promise.all(
        collectionUids.map((collectionUid) =>
          copyCardToCollection({ cardUid: cardUid, collectionUid }),
        ),
      ),
    onSuccess: () => dispatch(ContentAction.getContent(ContentTypesEnum.CARD)),
  });
};
