class Activity {
  readonly date: Date;
  readonly cardUids: string[];
  readonly userUids: string[];
  readonly amount: number;

  constructor(date: string, cardUids: string[], userUids: string[]) {
    this.date = new Date(date);
    this.cardUids = cardUids;
    this.userUids = userUids;
    this.amount = cardUids.length;
  }
}

export interface CompletedCardsResponse {
  [date: string]: { cardUid: string; userUid: string }[];
}

export default class CompletedCardsModel {
  data: Activity[];

  constructor(response: CompletedCardsResponse) {
    this.data = Object.entries(response).map(
      ([date, activity]) =>
        new Activity(
          date,
          activity.map((a) => a.cardUid),
          activity.map((a) => a.userUid),
        ),
    );
  }
}
