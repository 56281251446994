class Activity {
  readonly date: Date;
  readonly userUids: string[];
  readonly amount: number;

  constructor(date: string, userUids: string[]) {
    this.date = new Date(date);
    this.userUids = userUids;
    this.amount = userUids.length;
  }
}

export interface UsersActivityResponse {
  [date: string]: string[];
}

export default class UsersActivityModel {
  data: Activity[];

  constructor(response: UsersActivityResponse) {
    this.data = Object.entries(response).map(
      ([date, userUids]) => new Activity(date, userUids),
    );
  }
}
