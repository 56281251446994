import React from "react";
import { ColumnsType } from "antd/lib/table";
import { Space, Table, Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import ContentActionComponent from "../../components/content-action/content-action.component";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import InvitationModel from "../../../core/models/invitation/invitation.model";
import Moment from "moment";
import { useGetUserGroups } from "../../../core/api/primio/primioComponents";
import { GroupTags } from "../../components/group-tag";

interface InvitationsOverviewProps {
  invitations: InvitationModel[];
}

const CompletedInvitationsScreen = (props: InvitationsOverviewProps) => {
  const { t } = useTranslation();
  const { invitations } = props;

  const NO_GROUPS = Capitalize(t("translations:common.no-groups"));
  const { data: userGroups = [] } = useGetUserGroups(
    {},
    {
      select: (data) => {
        const response = data.map((item) => item.groupType.GroupName);
        response.unshift(NO_GROUPS);
        return response;
      },
    },
  );

  const columns: ColumnsType<InvitationModel> = [
    {
      key: "name",
      title: Capitalize(t("form.items.name.label")),
      dataIndex: "name",
      ...GetColumnSearchProps({ dataIndex: "name" }),
    },
    {
      key: "email",
      title: Capitalize(t("form.items.email.label")),
      dataIndex: "email",
      ...GetColumnSearchProps({ dataIndex: "email" }),
    },
    {
      key: "userGroupAcl",
      title: Capitalize(t("containers.user-groups.key_plural")),
      width: 250,
      filters: userGroups.map((group) => ({
        text: group,
        value: group,
      })),
      onFilter: (value, record) =>
        value === NO_GROUPS
          ? record.userGroups.length === 0
          : record.userGroups.includes(value as string),
      render: (_, data) => <GroupTags groupNames={data.userGroups ?? []} />,
    },
    {
      key: "completed_at",
      title: (
        <Typography.Text ellipsis style={{ width: 120 }}>
          {Capitalize(t("common.accepted-at"))}
        </Typography.Text>
      ),
      dataIndex: "completedAt",
      sorter: (a, b) => {
        if (!a.completedAt || !b.completedAt) {
          return 0;
        }
        return +new Date(a.completedAt) - +new Date(b.completedAt);
      },
      render: function renderText(_, data) {
        if (!data.completedAt) {
          return <Typography.Text>-</Typography.Text>;
        }
        return (
          <Typography.Text>
            {Moment(data.completedAt).format("DD/MM/YYYY (LT)")}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "action",
      width: 1,
      fixed: "right" as const,
      render: function showActions(_, data) {
        return <Space size={"middle"}>{handleShowActions(data)}</Space>;
      },
    },
  ];

  return (
    <Table<InvitationModel>
      dataSource={invitations}
      columns={columns}
      rowKey={(data) => data.invitationUid}
      pagination={false}
      size={"small"}
    />
  );

  function handleShowActions(data: InvitationModel) {
    const username = data.email
      .toLocaleLowerCase()
      .replace(/[!#,*^$&(){}+.@-]/g, "_");
    return (
      <Link to={{ pathname: `/users/${username}` }}>
        <ContentActionComponent
          icon={<ContextActionIconComponent action={"open"} />}
          label={Capitalize(t("common.open"))}
          hideLabel={true}
        />
      </Link>
    );
  }
};
export default CompletedInvitationsScreen;
