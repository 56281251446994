import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { DateRange } from "../enums/date-range.enum";
import { useCompletedCards } from "../hooks/user-progress/user-progress.hook";
import useDateRangeHelper from "../hooks/useDateRangeHelper";
import UsersActivityModel from "../models/user-progress/users-activity.model";
import CompletedCardsModel from "../models/user-progress/completed-cards.model";
import { BarGraphData } from "../../views/components/graphs/atoms/bar-graph";
import { AverageCompletedCardsInterface } from "../interfaces/average-completed-cards.interface";
import onlyUnique from "../utils/onlyUnique.util";

interface Props {
  range: DateRange;
}

interface ActivityGraphType {
  userActivity?: UsersActivityModel;
  completedCards?: CompletedCardsModel;
  averageCompletedCards?: AverageCompletedCardsInterface;
  isFetching: boolean;
  formatData: (date: Date, amount: number) => BarGraphData;
}

const ActivityGraphContext = createContext({} as ActivityGraphType);

const ActivityGraphProvider = ({
  children,
  range,
}: PropsWithChildren<Props>) => {
  const { props, formatData } = useDateRangeHelper(range);
  const {
    isFetching,
    data: completedCards,
    refetch: refetchCompletedCards,
  } = useCompletedCards(props);

  const [userActivity, setUserActivity] = useState<UsersActivityModel>();
  const [averageCompletedCards, setAverageCompletedCards] =
    useState<AverageCompletedCardsInterface>();

  useEffect(() => {
    refetchCompletedCards();
  }, [props]);

  useEffect(() => {
    if (!completedCards) {
      return setUserActivity(undefined);
    }

    const userActivity: UsersActivityModel = new UsersActivityModel({});
    userActivity.data = completedCards.data.map((day) => {
      const uniqueUsers = day.userUids.filter(onlyUnique);

      return {
        date: day.date,
        amount: uniqueUsers.length,
        userUids: uniqueUsers,
      };
    });

    setUserActivity(userActivity);
  }, [completedCards]);

  useEffect(() => {
    if (!userActivity || !completedCards) {
      return setAverageCompletedCards(undefined);
    }

    setAverageCompletedCards({
      data: userActivity.data.map((user, index) => {
        let amount = 0;

        if (!completedCards.data[index]) {
          amount = 0;
        } else if (completedCards.data[index].amount && user.amount) {
          amount = completedCards.data[index].amount / user.amount;
        }

        return {
          amount,
          date: user.date,
          userUids: user.userUids,
          cardUids: completedCards.data[index]
            ? completedCards.data[index].cardUids
            : [""],
        };
      }),
    });
  }, [userActivity, completedCards]);

  return (
    <ActivityGraphContext.Provider
      value={{
        userActivity,
        completedCards,
        averageCompletedCards,
        formatData,
        isFetching,
      }}
    >
      {children}
    </ActivityGraphContext.Provider>
  );
};

const useActivityGraphContext = () => useContext(ActivityGraphContext);

export { ActivityGraphProvider, useActivityGraphContext };
