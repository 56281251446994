import { EffectType } from "../../../types/redux.type";
import { UserCreateInterface } from "../../../interfaces/user-create.interface";
import UserEndpoints from "../../../api/user.endpoints";
import HttpErrorResponseModel from "../../../models/http-error-response.model";
import EffectUtility from "../../../utils/effect.utils";
import UserModel from "../../../models/user/user.model";
import HttpUtility from "../../../utils/http.utils";

export default class UserEffect {
  static getCurrentUser() {
    const endpoint = UserEndpoints.user;
    return EffectUtility.getToModel<UserModel>(UserModel, endpoint);
  }

  /**
   * Get all Users via GET Request
   * @returns Promise UserModel[]
   */
  static getAllUsers(): EffectType<UserModel[]> {
    const endpoint = UserEndpoints.overview;
    return EffectUtility.getToModel<UserModel[]>(UserModel, endpoint);
  }

  /**
   * Get User (with UserGroups) via GET Request
   * @param user UserModel
   * @returns Promise UserModel
   */
  static getUserWithGroups(user: UserModel): EffectType<UserModel> {
    return UserEffect.getGroupsForUser(user.username).then((userGroups) => {
      user.userGroups = userGroups;
      return user;
    });
  }

  /**
   * Get UserGroups for User via GET Request
   * @param username string
   * @returns Promise string[]
   */
  static async getGroupsForUser(username: string): Promise<string[]> {
    const endpoint = UserEndpoints.groupsForUser(username);
    const response = await HttpUtility.get(endpoint);

    if (
      response instanceof HttpErrorResponseModel ||
      !response ||
      !response.data
    ) {
      throw new Error(`${response.status} ${response.message}`);
    }

    return response.data as string[];
  }

  /**
   * Delete User via DELETE Request
   * @param user UserModel
   * @returns Promise HttpRequest
   */
  static deleteUser(user: UserModel): EffectType<UserModel> {
    const endpoint = UserEndpoints.delete(user);
    return HttpUtility.delete(endpoint);
  }

  /**
   * Create User via POST Request
   * @param data UserCreateInterface
   * @returns timestamp
   */
  static createUser(data: UserCreateInterface): EffectType<UserModel> {
    const endpoint = UserEndpoints.overview;
    return EffectUtility.postToModel<UserModel>(UserModel, endpoint, data);
  }
}
