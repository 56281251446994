import React, { useEffect, useState } from "react";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import { Button, Card, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useHistory } from "react-router-dom";
import BlockStatisticsComponent from "../../statistics/block-statistics.component";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import styles from "./app-metrics.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectContentTrees } from "../../../../core/redux/selectors/content/content.selector";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import PlaybookDetailModel from "../../../../core/models/content/playbook-detail.model";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import { selectRequesting } from "../../../../core/redux/selectors/requesting/requesting.selector";

const ContentStatisticsComponent = () => {
  const { t } = useTranslation();
  const contentTree: PlaybookDetailModel[] = useSelector(selectContentTrees);
  const [playbookCount, setPlaybookCount] = useState(0);
  const [chapterCount, setChapterCount] = useState(0);
  const [collectionCount, setCollectionCount] = useState(0);
  const [cardCount, setCardCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const isFetchingContent = useSelector((state) =>
    selectRequesting(state, [
      ContentAction.REQUEST_PLAYBOOKS,
      ContentAction.REQUEST_CHAPTERS,
      ContentAction.REQUEST_COLLECTIONS,
      ContentAction.REQUEST_CARDS,
    ]),
  );

  useEffect(() => {
    dispatch(ContentAction.getAllContent());
  }, []);

  useEffect(() => {
    let publishedPlaybooks = 0;
    let publishedChapters = 0;
    let publishedCollections = 0;
    let publishedCards = 0;

    contentTree.forEach((playbook) => {
      if (playbook.contentState !== ContentStatesEnum.PUBLISHED) {
        return;
      }
      publishedPlaybooks++;
      if (!playbook.children) {
        return;
      }
      playbook.children.forEach((chapter) => {
        if (chapter.contentState !== ContentStatesEnum.PUBLISHED) {
          return;
        }
        publishedChapters++;
        if (!chapter.children) {
          return;
        }

        chapter.children.forEach((collection) => {
          if (collection.contentState !== ContentStatesEnum.PUBLISHED) {
            return;
          }
          publishedCollections++;
          if (!collection.children) {
            return;
          }
          publishedCards += collection.children.filter(
            (c) => c.contentState === ContentStatesEnum.PUBLISHED,
          ).length;
        });
      });
    });

    setPlaybookCount(publishedPlaybooks);
    setChapterCount(publishedChapters);
    setCollectionCount(publishedCollections);
    setCardCount(publishedCards);
  }, [contentTree]);

  return (
    <div style={{ marginRight: "2rem", width: "50%" }}>
      <Card className={styles.card_wrapper}>
        <div className={styles.card_container}>
          <BlockStatisticsComponent
            value={playbookCount}
            title={t(
              `content.${ContentTypesEnum.PLAYBOOK.toLowerCase()}.key_plural`,
            )}
            loading={isFetchingContent}
          />

          <Divider type={"vertical"} className={styles.blocks_divider} />

          <BlockStatisticsComponent
            value={chapterCount}
            title={t(
              `content.${ContentTypesEnum.CHAPTER.toLowerCase()}.key_plural`,
            )}
            loading={isFetchingContent}
          />

          <Divider type={"vertical"} className={styles.blocks_divider} />

          <BlockStatisticsComponent
            value={collectionCount}
            title={t(
              `content.${ContentTypesEnum.COLLECTION.toLowerCase()}.key_plural`,
            )}
            loading={isFetchingContent}
          />

          <Divider type={"vertical"} className={styles.blocks_divider} />

          <BlockStatisticsComponent
            value={cardCount}
            title={t(
              `content.${ContentTypesEnum.CARD.toLowerCase()}.key_plural`,
            )}
            loading={isFetchingContent}
          />
        </div>
      </Card>

      <Button
        type={"text"}
        className={styles.button}
        onClick={() => history.push(RoutesEnum.PUBLISHED_CONTENT)}
      >
        {Capitalize(t("common.show")) +
          " " +
          t("containers.published-content.title")}
      </Button>
    </div>
  );
};

export default ContentStatisticsComponent;
