import React, { useState } from "react";
import { DateRange } from "../../../core/enums/date-range.enum";
import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import CompletedCardsGraph from "../graphs/completed-cards/completed-cards.graph";
import AverageCompletedCardsGraph from "../graphs/average-cards-per-user/average-completed-cards.graph";
import SectionTitleComponent from "../section-title/section-title.component";
import DateRangeComponent from "../actions/date-range.component";
import ActiveUsersGraph from "../graphs/active-users/active-users.graph";
import styles from "./activity.module.css";
import { ActivityGraphProvider } from "../../../core/providers/activity-graph.provider";

const ActivityGraphsComponent = () => {
  const { t } = useTranslation();
  const [range, setRange] = useState(DateRange.THIS_WEEK);

  return (
    <div>
      <div className={styles.header}>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <SectionTitleComponent
            title={Capitalize(t("graphs.activity-screen.title"))}
          />
          <Tooltip title={t("graphs.activity-screen.tooltip")}>
            <ExclamationCircleOutlined
              style={{ marginLeft: 10, marginTop: 5 }}
            />
          </Tooltip>
        </div>

        <DateRangeComponent
          range={range}
          options={[
            DateRange.THIS_WEEK,
            DateRange.THIS_MONTH,
            DateRange.LAST_WEEK,
            DateRange.LAST_MONTH,
          ]}
          onChange={setRange}
        />
      </div>

      <ActivityGraphProvider range={range}>
        <div className={styles.container}>
          <div className={styles.item}>
            <ActiveUsersGraph />
          </div>
          <div className={styles.item}>
            <CompletedCardsGraph />
          </div>
          <div className={styles.item}>
            <AverageCompletedCardsGraph />
          </div>
        </div>
      </ActivityGraphProvider>
    </div>
  );
};

export default ActivityGraphsComponent;
