import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Typography } from "antd";
import Empty from "../../empty/empty";
import BarGraph from "../atoms/bar-graph";
import styles from "./graph-container.module.css";
import UsersActivityModel from "../../../../core/models/user-progress/users-activity.model";
import CompletedCardsModel from "../../../../core/models/user-progress/completed-cards.model";
import { useActivityGraphContext } from "../../../../core/providers/activity-graph.provider";

interface GraphContainerProps {
  title: string;
  color: { from: string; to: string };
  renderToolTip: (value: number) => ReactNode;

  data: UsersActivityModel | CompletedCardsModel | undefined;
  isFetching: boolean;
}

const GraphContainer = ({
  color,
  title,
  renderToolTip,
  data,
  isFetching,
}: GraphContainerProps) => {
  const { t } = useTranslation();
  const { formatData } = useActivityGraphContext();
  const [totalAmount, setTotalAmount] = useState(0);
  const height = 250;

  useEffect(() => {
    if (!data) {
      return;
    }

    let totalAmount = 0;
    data.data.forEach((value) => {
      totalAmount += value.amount;
    });
    setTotalAmount(totalAmount);
  }, [data]);

  if (isFetching) {
    return (
      <div className={styles.container}>
        <Typography.Text className={styles.title}>{title}</Typography.Text>
        <div style={{ height }} className={styles.container_spinner}>
          <div className={styles.container_spinner_item}>
            <Spin />
          </div>
        </div>
      </div>
    );
  }

  if (!data || !data.data) {
    return (
      <div className={styles.container}>
        <Typography.Text className={styles.title}>{title}</Typography.Text>
        <div style={{ height }} className={styles.container_spinner}>
          <div className={styles.container_spinner_item}>
            <Empty image={"none_found"} message={t("errors.general")} />
          </div>
        </div>
      </div>
    );
  }

  if (totalAmount === 0) {
    return (
      <div className={styles.container}>
        <Typography.Text className={styles.title}>{title}</Typography.Text>
        <div style={{ height }} className={styles.container_spinner}>
          <div className={styles.container_spinner_item}>
            <Empty
              image={"none_found"}
              message={t("errors.empty.no-activity-in-current-period")}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Typography.Text className={styles.title}>{title}</Typography.Text>

      <BarGraph
        height={height - 20}
        colors={color}
        data={data.data.map((value) => formatData(value.date, value.amount))}
        renderToolTip={(data) => renderToolTip(data.vertical)}
      />
    </div>
  );
};

export default GraphContainer;
