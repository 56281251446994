import React, { Key, useEffect, useRef, useState } from "react";
import { Button, Drawer, Input, Space, Table, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useApiKeyClients } from "../../../../core/hooks/api-key/api-key.hook";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import {
  useGetCategories,
  useGetPlaybooks,
} from "../../../../core/api/primio/primioComponents";
import { Playbook } from "../../../../core/api/primio/primioSchemas";
import { SorterResult, TableRowSelection } from "antd/lib/table/interface";
import { ColumnsType, TableProps } from "antd/lib/table";
import { FilterValue } from "antd/es/table/interface";
import ContentState from "../../content-state/content-state";
import insert from "../../../../core/utils/insert";
import onlyUnique from "../../../../core/utils/onlyUnique.util";
import styles from "./playbooks-select.module.css";
import UserGroupModel from "../../../../core/models/user-group/user-group.model";
import { useSelector } from "react-redux";
import { selectUserGroupsFromLearningPathContent } from "../../../../core/redux/selectors/learning-path/learning-path.selector";
import PlaybookLearningPathContext from "../../utils/playbook-learning-path-context";
import { GroupTags } from "../../group-tag";

type Props = {
  playbookUids: string[];
  onChange?: (value: SelectedPlaybookUidsType[]) => void;
  onClose?: () => void;
  mode?: "multiple" | "single";
  filter?: (value: Playbook, index: number, array: Playbook[]) => boolean;
  userGroup?: UserGroupModel | undefined;
  openDrawerImmediately?: boolean;
};

export interface SelectedPlaybookUidsType {
  playbookUid: string;
  isShared?: boolean;
}

const PlaybooksSelectComponent = ({
  playbookUids,
  onChange,
  onClose,
  mode = "multiple",
  filter = () => true,
  userGroup,
  openDrawerImmediately = false,
}: Props) => {
  const [t] = useTranslation();
  const { data: playbooks = [] } = useGetPlaybooks(
    {
      queryParams: { excludeMedia: true },
    },
    {
      select: (data) => data.filter(filter),
    },
  );

  const { data: apiKeyClients = [] } = useApiKeyClients();
  const [selectedPlaybooks, setSelectedPlaybooks] = useState<Playbook[]>([]);
  const [displayPlaybooks, setDisplayPlaybooks] = useState<Playbook[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(openDrawerImmediately);
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Playbook>>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [displayedRowKeys, setDisplayedRowKeys] = useState<Key[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [groups, setGroups] = useState<string[]>([]);
  const { data: categories = [] } = useGetCategories({});
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const [invisibleIndexes, setInvisibleIndexes] = useState<number[]>([]);
  const updatedLearningPaths = useSelector(
    selectUserGroupsFromLearningPathContent,
  );

  useEffect(() => {
    if (!outerContainerRef.current) {
      return;
    }

    const containerPadding = 4;
    const itemMargin = 10;
    const containerWidth =
      outerContainerRef.current.clientWidth + containerPadding * 2;

    const itemSizes: number[] = [];

    for (let i = 0; i < outerContainerRef.current.children.length; i++) {
      const child = outerContainerRef.current.children[i];
      itemSizes.push(child.clientWidth + itemMargin);
    }

    const invisibleIndexes: number[] = [];

    itemSizes.forEach((width, index, array) => {
      const sliced = array.slice(0, index + 1);
      const sum = sliced.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
      );
      const isOverflowing = sum > containerWidth;
      if (isOverflowing) {
        invisibleIndexes.push(index);
      }
    });

    setInvisibleIndexes(invisibleIndexes);
  }, [outerContainerRef, selectedPlaybooks]);

  useEffect(() => {
    setFilteredInfo({
      ...filteredInfo,
      title: [searchTerm],
    });
  }, [searchTerm]);

  useEffect(() => {
    const groups: string[] = [];

    playbooks.forEach((playbook) => {
      groups.push(...playbook.userGroupAcl);
    });

    setGroups(groups.filter(onlyUnique));

    if (playbooks.length > 0 && playbookUids.length > 0) {
      const selectedPlaybooks = playbooks.filter((playbook) =>
        playbookUids.includes(playbook.playbookUid),
      );
      setSelectedPlaybooks(selectedPlaybooks);
      setDisplayPlaybooks(selectedPlaybooks);
      setSelectedRowKeys(selectedPlaybooks.map((p) => p.playbookUid));
      setDisplayedRowKeys(selectedPlaybooks.map((p) => p.playbookUid));
    } else {
      setSelectedPlaybooks([]);
      setDisplayPlaybooks([]);
      setSelectedRowKeys([]);
      setDisplayedRowKeys([]);
    }
  }, [playbooks, playbookUids]);

  const getCategoriesFromPlaybook = (playbookUid: string) =>
    categories.filter((category) =>
      category.playbooks
        .map((playbook) => playbook.playbookUid)
        .includes(playbookUid),
    );

  let columns: ColumnsType<Playbook> = [
    {
      key: "title",
      title: Capitalize(t("form.items.name.label")),
      sortOrder: sortedInfo.columnKey === "title" ? sortedInfo.order : null,
      filteredValue: filteredInfo.title,
      onFilter: (value, record) =>
        record.title.toLowerCase().includes(value.toLocaleString() as string),
      sorter: (a, b) => {
        if (a.title > b.title) {
          return 1;
        }
        if (b.title > a.title) {
          return -1;
        }
        return 0;
      },
      render: function renderText(_, data) {
        return <Typography.Text>{data.title}</Typography.Text>;
      },
    },
    {
      key: "contentState",
      title: Capitalize(t("content-state.state")),
      filteredValue: filteredInfo.contentState || null,
      filters: Object.entries(ContentStatesEnum).map(([key, value]) => ({
        text: Capitalize(t(`translations:content-state.${key}`)),
        value: value,
      })),
      onFilter: (value, record) => record.contentState === value,
      sortOrder:
        sortedInfo.columnKey === "contentState" ? sortedInfo.order : null,
      sorter: (a, b) => {
        const code = (contentState: "PUBLISHED" | "DRAFT" | "DELETED") => {
          switch (contentState) {
            case ContentStatesEnum.PUBLISHED:
              return 2;
            case ContentStatesEnum.DRAFT:
              return 1;
            default:
              return 0;
          }
        };

        return code(a.contentState) - code(b.contentState);
      },
      render: function renderText(_, data) {
        return (
          <ContentState contentState={data.contentState as ContentStatesEnum} />
        );
      },
    },
    {
      key: "categories",
      title: Capitalize(t("containers.categories.key_plural")),
      filteredValue: filteredInfo.categories || null,
      filters: categories.map((category) => ({
        text: category.title,
        value: category.categoryUid,
      })),
      onFilter: (categoryUid, record) => {
        const categories = getCategoriesFromPlaybook(record.playbookUid);

        if (categories.length === 0 || typeof categoryUid !== "string") {
          return false;
        }

        return !!categories.find((c) => c.categoryUid === categoryUid);
      },
      render: function renderText(_, data) {
        const categories = getCategoriesFromPlaybook(data.playbookUid);

        return categories.map((c, index) => (
          <Tag key={index} color={"purple"}>
            {c.title}
          </Tag>
        ));
      },
    },
    {
      key: "userGroupAcl",
      title: Capitalize(t("containers.user-groups.key_plural")),
      filteredValue: filteredInfo.userGroupAcl || null,
      filters: groups.map((group) => ({
        text: group,
        value: group,
      })),
      onFilter: (value, record) =>
        record.userGroupAcl.includes(value as string),
      render: (_, data) => <GroupTags groupNames={data.userGroupAcl} />,
    },
  ];

  if (playbooks.some((p) => p.apiKeyClientUid !== undefined)) {
    const apiKeyColumn = {
      key: "apiKeyClientUid",
      title: Capitalize(t("common.source")),
      sortOrder:
        sortedInfo.columnKey === "apiKeyClientUid" ? sortedInfo.order : null,
      sorter: (a, b) => {
        if (!a.apiKeyClientUid || !b.apiKeyClientUid) {
          return -1;
        }
        if (a.apiKeyClientUid > b.apiKeyClientUid) {
          return 1;
        }
        if (b.apiKeyClientUid > a.apiKeyClientUid) {
          return -1;
        }
        return 0;
      },
      render: function renderText(_, data) {
        const server = apiKeyClients?.find(
          (a) => a.apiKeyClientUid === data.apiKeyClientUid,
        );

        if (!server) {
          return;
        }

        return <Typography.Text>{Capitalize(server.server)}</Typography.Text>;
      },
    };

    columns = insert(columns, 4, apiKeyColumn);
  }

  const handleChange: TableProps<Playbook>["onChange"] = (
    pagination,
    filters,
    sorter,
  ) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<Playbook>);
  };

  const rowSelection: TableRowSelection<Playbook> = {
    type: mode === "multiple" ? "checkbox" : "radio",
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedPlaybooks(selectedRows);
    },
    onSelect: async (_r, _s, selectedRows) => {
      const { checkPlaybookContext } = PlaybookLearningPathContext(
        userGroup,
        _r,
        updatedLearningPaths,
      );

      await checkPlaybookContext().then((res) => {
        if (res) {
          const filteredRows = selectedRows.filter(
            (row) => row.playbookUid !== _r.playbookUid,
          );

          setSelectedRowKeys(selectedRowKeys);
          setSelectedPlaybooks(_s ? filteredRows : [...filteredRows, _r]);
        } else {
          setSelectedPlaybooks(selectedRows);
        }
        return;
      });
    },
  };

  function handleOnClose() {
    onClose?.();
    setIsDrawerOpen(false);
    setSelectedRowKeys(displayedRowKeys);
    setSelectedPlaybooks(displayPlaybooks);
  }

  function handleOnChange(value: Playbook[]) {
    setDisplayPlaybooks(value);
    setDisplayedRowKeys(selectedRowKeys);

    if (!onChange) {
      return;
    }

    const results = value.map((playbook) => {
      return {
        playbookUid: playbook.playbookUid,
        isShared: !!playbook.apiKeyClientUid,
      };
    });

    return onChange(results);
  }

  return (
    <>
      <div
        className={styles.PlaybookPicker}
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <div
          ref={outerContainerRef}
          className={styles.PlaybookPicker_InnerContainer}
        >
          {displayPlaybooks.length === 0 ?? (
            <p>
              {Capitalize(
                t("translations:form.placeholders.select_x", {
                  x: t("translations:content.playbook.key"),
                }),
              )}
            </p>
          )}

          {displayPlaybooks.map((playbook, index) => (
            <span key={index}>{playbook.title}</span>
          ))}
        </div>
        {invisibleIndexes.length > 0 && (
          <div className={styles.PlaybookPicker_OverflowLabel}>
            <span>+ {invisibleIndexes.length} ...</span>
          </div>
        )}
      </div>

      <Drawer
        open={isDrawerOpen}
        width={900}
        onClose={handleOnClose}
        title={`${Capitalize(
          t("translations:form.placeholders.select_x", {
            x: t("translations:content.playbook.key"),
          }),
        )} ${
          selectedPlaybooks.length > 0 ? `(${selectedPlaybooks.length})` : ""
        }`}
        extra={
          <Space>
            <Input
              placeholder={Capitalize(t("translations:common.search"))}
              onChange={(e) => setSearchTerm(e.target.value)}
              allowClear
            />
            <Button
              type={"primary"}
              onClick={() => {
                handleOnChange([...selectedPlaybooks]);
                handleOnClose();
              }}
            >
              {Capitalize(t("translations:common.submit"))}
            </Button>
          </Space>
        }
        bodyStyle={{
          position: "relative",
          padding: 0,
        }}
      >
        <Table
          dataSource={playbooks}
          className={"custom-fixed-header"}
          onChange={handleChange}
          scroll={{ y: 900 }}
          pagination={false}
          rowKey={(playbook) => playbook.playbookUid}
          size={"small"}
          columns={columns}
          showSorterTooltip={false}
          rowSelection={rowSelection}
        />
      </Drawer>
    </>
  );
};

export default PlaybooksSelectComponent;
