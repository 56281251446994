import React from "react";
import { GradientColors_GraphBlue } from "../../../styles/colors";
import { useTranslation } from "react-i18next";
import GraphContainer from "../graph-container/graph-container";
import { useActivityGraphContext } from "../../../../core/providers/activity-graph.provider";

const ActiveUsersGraph = () => {
  const { t } = useTranslation();
  const { userActivity, isFetching } = useActivityGraphContext();

  return (
    <GraphContainer
      title={t("graphs.users-active.title")}
      color={GradientColors_GraphBlue}
      data={userActivity}
      isFetching={isFetching}
      renderToolTip={(value) => (
        <strong>
          {value} {t("containers.users.key", { count: value })}
        </strong>
      )}
    />
  );
};

export default ActiveUsersGraph;
