import ThunkInterface from "../../../interfaces/thunk.interface";
import UserModel from "../../../models/user/user.model";
import BaseReducer from "../base/base.reducer";
import UserAction from "./user.action";

export interface UserReducerInterface {
  users: UserModel[];
  currentUser: UserModel | undefined;
}

export default class UserReducer extends BaseReducer<UserReducerInterface> {
  initialState: UserReducerInterface = {
    users: [],
    currentUser: undefined,
  };

  [UserAction.REQUEST_USERS_FINISHED](
    state: UserReducerInterface,
    action: ThunkInterface<UserModel[]>,
  ) {
    return {
      ...state,
      users: action.payload,
    };
  }

  [UserAction.REQUEST_DELETE_USER_FINISHED](
    state: UserReducerInterface,
    action: ThunkInterface<UserModel>,
  ) {
    return {
      ...state,
      users: this.removeSingleFromArray<UserModel>(
        action.meta.args[0],
        [...(state.users as UserModel[])],
        "username",
      ),
    };
  }

  [UserAction.REQUEST_USER_FINISHED](
    state: UserReducerInterface,
    action: ThunkInterface<UserModel>,
  ) {
    return {
      ...state,
      currentUser: action.payload,
    };
  }
}
