import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { LinearGradient } from "@visx/gradient";
import { Tooltip, useTooltip } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { grey } from "@ant-design/colors";

export interface BarGraphData {
  vertical: number;
  horizontal: string;
}

interface BarGraphProps {
  data: BarGraphData[];
  colors: { from: string; to: string };
  height?: number;
  width?: number;
  margin?: { top: number; bottom: number; left: number; right: number };
  numTicks?: number;
  enableTooltip?: boolean;
  renderToolTip?: (data: BarGraphData) => ReactNode;
}

const BarGraph = ({
  data,
  colors,
  height: propsHeight = 320,
  margin = { top: 20, bottom: 20, left: 35, right: 8 },
  numTicks = 6,
  renderToolTip,
  enableTooltip = true,
  ...props
}: BarGraphProps) => {
  const id = useRef(Math.random().toString(16).slice(-4)).current;
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(props.width ? props.width : 0);
  const [height, setHeight] = useState(propsHeight ? propsHeight : 0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (width !== ref.current.offsetWidth) {
      setWidth(ref.current.offsetWidth);
    }

    if (height !== ref.current.offsetHeight) {
      setHeight(ref.current.offsetHeight);
    }
  }, [ref.current, propsHeight, props.width]);

  // Then we'll create some bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // We'll make some helpers to get at the data we want
  const x = (d) => d.horizontal;
  const y = (d) => +d.vertical;

  // And then scale the graph by our data
  const xScale = scaleBand({
    range: [0, xMax],
    domain: data.map(x),
    padding: 0.4,
  });
  const yScale = scaleLinear({
    range: [yMax, 0],
    nice: true,
    round: true,
    domain: [0, Math.max(...data.map(y))],
  });

  // Compose together the scale and accessor functions to get point functions
  const compose = (scale, accessor) => (data) => scale(accessor(data));
  const xPoint = compose(xScale, x);
  const yPoint = compose(yScale, y);

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<BarGraphData>();

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <LinearGradient {...colors} id={`gradient-${id}`} />
        <GridRows
          numTicks={numTicks}
          scale={yScale}
          left={margin.left}
          top={margin.top}
          width={innerWidth}
          height={innerHeight}
        />
        {data.map((d, i) => {
          const barHeight = yMax - yPoint(d);
          return (
            <Group key={`bar-${i}`} left={margin.left}>
              <Bar
                x={xPoint(d)}
                y={yMax + margin.top - barHeight}
                height={barHeight}
                rx={6}
                width={xScale.bandwidth()}
                fill={`url(#gradient-${id})`}
                onMouseLeave={hideTooltip}
                onMouseMove={(event) => {
                  // TooltipInPortal expects coordinates to be relative to containerRef
                  // localPoint returns coordinates relative to the nearest SVG, which
                  // is what containerRef is set to in this example.
                  const eventSvgCoords = localPoint(event);
                  const left = xPoint(d) + xScale.bandwidth();
                  showTooltip({
                    tooltipData: d,
                    tooltipTop: eventSvgCoords
                      ? eventSvgCoords.y - 40
                      : undefined,
                    tooltipLeft: left,
                  });
                }}
              />
            </Group>
          );
        })}
        <AxisLeft
          left={margin.left}
          top={margin.top}
          scale={yScale}
          hideAxisLine
          numTicks={numTicks}
          hideTicks
          tickLabelProps={() => ({
            fontSize: 12,
            textAnchor: "end",
            fill: grey[2],
          })}
        />
        <AxisBottom
          scale={xScale}
          top={yMax + margin.top}
          left={margin.left}
          hideAxisLine
          hideTicks
          tickLabelProps={() => ({
            fontSize: 12,
            textAnchor: "middle",
            fill: grey[2],
          })}
        />
      </svg>

      {enableTooltip && tooltipOpen && tooltipData && (
        <Tooltip top={tooltipTop} left={tooltipLeft}>
          <>{!!renderToolTip && renderToolTip(tooltipData)}</>
        </Tooltip>
      )}
    </div>
  );
};

export default BarGraph;
