import React, { useEffect, useState } from "react";

interface Props {
  path: string;
}

const SpotifyMediaComponent = ({ path }: Props) => {
  const [height, setHeight] = useState<number>(380);

  useEffect(() => {
    if (path.includes("track")) {
      setHeight(80);
    }
  }, []);

  return (
    <iframe
      src={"https://open.spotify.com/embed/" + path}
      width={"100%"}
      height={height}
      frameBorder={"0"}
      allow={"clipboard-write; encrypted-media; fullscreen; picture-in-picture"}
    />
  );
};

export default SpotifyMediaComponent;
