import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Switch } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { PlusOutlined } from "@ant-design/icons";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { SourceCreateEditInterface } from "../../../core/interfaces/source-create-edit.interface";
import SourceModel from "../../../core/models/source/source.model";
import SourceAction from "../../../core/redux/stores/source/source.action";
import CreateSourceForm, {
  CreateSourceFormRef,
  CreateSourceInterface,
} from "../forms/create-source.form";
import colors from "../../styles/colors";
import CardTypes from "../../../core/types/card.types";

interface OwnProps {
  cardContent: CardTypes;
}

type Props = OwnProps;

const SourceContainerComponent = ({ cardContent }: Props) => {
  const [t] = useTranslation();
  const [showSources, setShowSources] = useState<boolean>(
    !!(cardContent.sources && cardContent.sources.length > 0),
  );
  const [isEditVisible, setIsEditVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const createSourceRef = useRef<CreateSourceFormRef>(null);
  const dispatch = useDispatch();

  const requesting = useSelector((state) =>
    selectRequesting(state, [
      SourceAction.REQUEST_SOURCES,
      SourceAction.REQUEST_CREATE_SOURCE,
      SourceAction.REQUEST_EDIT_SOURCE,
      SourceAction.REQUEST_DELETE_SOURCE,
    ]),
  );

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);

      if (isSubmitting) {
        handleOnCancel();
      }
    }
  }, [requesting]);

  return (
    <Form.Item label={Capitalize(t("form.card.sources.label"))}>
      <Form.Item>
        <Switch checked={showSources} onChange={handleOnSwitchSource} />
      </Form.Item>

      {cardContent.sources &&
        cardContent.sources.length > 0 &&
        cardContent.sources.map((source, index) => (
          <CreateSourceForm
            key={index}
            ref={createSourceRef}
            source={source}
            onAdd={handleOnAddSource}
            onEdit={handleOnEditSource}
            onDelete={handleOnDeleteSource}
          />
        ))}

      {isEditVisible && (
        <CreateSourceForm
          ref={createSourceRef}
          source={undefined}
          onAdd={handleOnAddSource}
          onEdit={handleOnEditSource}
          onDelete={handleOnDeleteSource}
        />
      )}

      <Button
        type={"text"}
        onClick={handleOnClickAddSource}
        style={{ color: colors.primary }}
      >
        <PlusOutlined /> {t("form.card.sources.button")}
      </Button>
    </Form.Item>
  );

  function handleOnClickAddSource() {
    setIsEditVisible(true);
    setShowSources(true);
  }

  function handleOnCancel() {
    setIsEditVisible(false);
    if (createSourceRef.current) {
      createSourceRef.current.form.resetFields();
    }
  }

  function handleOnSwitchSource(checked) {
    setShowSources(checked);
    setIsEditVisible(checked);

    if (!checked && cardContent.sources) {
      cardContent.sources.forEach(handleOnDeleteSource);
    }
  }

  function handleOnAddSource() {
    if (createSourceRef.current) {
      createSourceRef.current.form
        .validateFields()
        .then((res: CreateSourceInterface) => {
          const newSource: SourceCreateEditInterface = {
            text: res.text,
            url: res.url,
            data: { source: [{ authorName: "", topic: "" }] },
            cardUid: cardContent.cardUid,
            cardVersion: cardContent.version,
          };

          dispatch(SourceAction.createSource(newSource));
          setIsSubmitting(true);
        });
    }
  }

  function handleOnEditSource(source: SourceModel) {
    if (createSourceRef.current) {
      createSourceRef.current.form
        .validateFields()
        .then((res: CreateSourceInterface) => {
          const newSource: SourceCreateEditInterface = {
            text: res.text,
            url: res.url,
            data: { source: [{ authorName: "", topic: "" }] },
            cardUid: cardContent.cardUid,
            cardVersion: cardContent.version,
          };

          dispatch(SourceAction.editSource(source, newSource));
          setIsSubmitting(true);
        });
    }
  }

  function handleOnDeleteSource(source: SourceModel) {
    dispatch(SourceAction.deleteSource(source));
    setIsSubmitting(true);
  }
};

export default SourceContainerComponent;
