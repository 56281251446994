export interface SourceDataInterface {
  source: [
    {
      authorName: string;
      topic: string;
    },
  ];
}

export interface SourceResponse {
  sourceUid: string;
  text: string;
  url: string;
  sort: number;
  data: SourceDataInterface;
  createdAt: Date;
  cardUid: string;
  cardVersion: number;
}

export default class SourceModel {
  sourceUid: string;
  text: string;
  url: string;
  sort: number;
  data: SourceDataInterface;
  createdAt: Date;
  cardUid: string;
  cardVersion: number;

  constructor(response: SourceResponse) {
    this.sourceUid = response.sourceUid;
    this.text = response.text;
    this.url = response.url;
    this.sort = response.sort;
    this.data = response.data;
    this.createdAt = response.createdAt;
    this.cardUid = response.cardUid;
    this.cardVersion = response.cardVersion;
  }
}
