import React from "react";
import GraphContainer from "../graph-container/graph-container";
import { GradientColors_GraphYellow } from "../../../styles/colors";
import { useTranslation } from "react-i18next";
import { useActivityGraphContext } from "../../../../core/providers/activity-graph.provider";

const AverageCompletedCardsGraph = () => {
  const { t } = useTranslation();
  const { averageCompletedCards, isFetching } = useActivityGraphContext();

  return (
    <GraphContainer
      title={t("graphs.average-completed-cards.title")}
      color={GradientColors_GraphYellow}
      data={averageCompletedCards}
      isFetching={isFetching}
      renderToolTip={(value) => {
        const amount = Math.round(value * 10) / 10;
        return (
          <strong>
            {amount}{" "}
            {t("graphs.average-completed-cards.tooltip", { count: amount })}
          </strong>
        );
      }}
    />
  );
};

export default AverageCompletedCardsGraph;
