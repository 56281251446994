import React from "react";
import GraphContainer from "../graph-container/graph-container";
import { useTranslation } from "react-i18next";
import { GradientColors_GraphPink } from "../../../styles/colors";
import { useActivityGraphContext } from "../../../../core/providers/activity-graph.provider";

const CompletedCardsGraph = () => {
  const { t } = useTranslation();
  const { completedCards, isFetching } = useActivityGraphContext();

  return (
    <GraphContainer
      title={t("graphs.completed-card.title")}
      color={GradientColors_GraphPink}
      data={completedCards}
      isFetching={isFetching}
      renderToolTip={(value) => (
        <strong>
          {value} {t("content.card.key", { count: value })}
        </strong>
      )}
    />
  );
};

export default CompletedCardsGraph;
