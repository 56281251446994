import React, { useRef } from "react";
import CardTypes from "../../../core/types/card.types";
import ContentType from "../../../core/types/content.type";
import { ContentActionProps } from "../content-action/content-action.component";
import ContentContextActionsComponent, {
  ContentContextRef,
} from "./content-context-actions.component";
import { EllipsisOutlined, StarOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import CollectionModel from "../../../core/models/content/collection.model";
import ExclusiveContentModal, {
  ExclusiveContentRef,
} from "../modals/exclusive-content.modal";
import Emitter from "../../../core/utils/emitter.utils";
import styles from "./content-context.module.css";
import ChapterModel from "../../../core/models/content/chapter.model";
import PlaybookModel from "../../../core/models/content/playbook.model";
import { useSelector } from "react-redux";
import { selectParentContentTree } from "../../../core/redux/selectors/content/content.selector";
import { LearningPathStateEnum } from "../../../core/enums/learning-path-state.enum";

interface Props {
  content: ContentType;
  onRename: (content: ContentType) => void;
}

function ContentContextMenu({ content }: Props) {
  const [t] = useTranslation();
  const contentContextActionsRef = useRef<ContentContextRef>(null);
  const exclusiveContentRef = useRef<ExclusiveContentRef>(null);
  const parents: (PlaybookModel | ChapterModel | CollectionModel)[] =
    useSelector((state) => selectParentContentTree(state, content));

  const actions: ContentActionProps[] = getActions(content);

  const dropdown = (
    <Menu>
      {actions.map((actionProps: ContentActionProps, index: number) => {
        const hasPlaybookConnectedToPostedLearningPaths =
          parents[parents.length - 1].learningPaths.filter(
            (learningPath) =>
              learningPath.state === LearningPathStateEnum.POSTED,
          ).length > 0;

        const isDisabled =
          content &&
          content.contentState === ContentStatesEnum.PUBLISHED &&
          index === actions.length - 1 &&
          hasPlaybookConnectedToPostedLearningPaths;

        let className = styles.context_menu;
        if (index === actions.length - 1) {
          className = styles.context_menu_publish;
        } else if (index === actions.length - 2 && index !== 0) {
          className = styles.context_menu_danger;
        }

        return (
          <Menu.ItemGroup
            key={`${actionProps.label}_${content?.contentType}_${content.getId}`}
          >
            {index === actions.length - 1 && <Menu.Divider />}
            <Menu.Item
              key={`${index.toString()}_${content.getId}`}
              className={className}
              disabled={isDisabled}
              title={
                isDisabled
                  ? t(
                      "translations:errors.context-menu.disabled-because-learning-path",
                    )
                  : undefined
              }
              icon={actionProps.icon}
              onClick={actionProps.onClick}
            >
              {actionProps.label}
            </Menu.Item>
          </Menu.ItemGroup>
        );
      })}
    </Menu>
  );

  return (
    <div>
      <Dropdown arrow overlay={dropdown}>
        <EllipsisOutlined />
      </Dropdown>
      <ContentContextActionsComponent ref={contentContextActionsRef} />
      <ExclusiveContentModal ref={exclusiveContentRef} />
    </div>
  );

  function duplicateContent() {
    if (!content) return;
    contentContextActionsRef.current?.duplicateContent(content);
  }

  function deleteContent() {
    if (!content) return;
    contentContextActionsRef.current?.deleteContent(content);
  }

  function publishContent() {
    if (!content) return;

    switch (content.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        Emitter.emit("handleBulkPublishContent", content as PlaybookModel);
        break;
      case ContentTypesEnum.CHAPTER:
        Emitter.emit("handleBulkPublishContent", content as ChapterModel);
        break;
      case ContentTypesEnum.COLLECTION:
        Emitter.emit("handleBulkPublishContent", content as CollectionModel);
        break;
      case ContentTypesEnum.CARD:
        Emitter.emit("handleBulkPublishContent", content as CardTypes);
        break;
    }
  }

  function draftContent() {
    if (!content) return;
    contentContextActionsRef.current?.draftContent(content);
  }

  function excludeContent() {
    if (!content) return;
    exclusiveContentRef.current?.excludeContent(content);
  }

  function getActions(content: ContentType): ContentActionProps[] {
    const _duplicate = {
      icon: (
        <ContextActionIconComponent
          action={"duplicate"}
          style={{ paddingRight: "1rem" }}
        />
      ),
      label: Capitalize(t("common.duplicate")),
      onClick: duplicateContent,
    };

    const _delete = {
      icon: (
        <ContextActionIconComponent
          action={"delete"}
          style={{ paddingRight: "1rem" }}
        />
      ),
      label: Capitalize(t("common.delete")),
      onClick: deleteContent,
    };

    const _draft = {
      icon: (
        <ContextActionIconComponent
          action={"draft"}
          style={{ paddingRight: "1rem" }}
        />
      ),
      label: Capitalize(t("common.draft-save")),
      onClick: draftContent,
    };

    const _publish = {
      icon: (
        <ContextActionIconComponent
          action={"publish"}
          style={{ paddingRight: "1rem" }}
        />
      ),
      label: Capitalize(t("common.publish")),
      onClick: publishContent,
    };

    const _exclusive = {
      icon: <StarOutlined style={{ paddingRight: "0.4rem" }} />,
      label: Capitalize(t("common.exclusive-manage")),
      onClick: excludeContent,
    };

    let actions: ContentActionProps[] = [
      _duplicate,
      _delete,
      content && content.contentState === ContentStatesEnum.PUBLISHED
        ? _draft
        : _publish,
    ];

    if (content?.contentType === ContentTypesEnum.PLAYBOOK) {
      actions = [
        _duplicate,
        _exclusive,
        _delete,
        content && content.contentState === ContentStatesEnum.PUBLISHED
          ? _draft
          : _publish,
      ];
    }

    if (
      content.getId.startsWith("start-summary") ||
      content.getId.startsWith("end-summary")
    ) {
      actions = [
        content && content.contentState === ContentStatesEnum.PUBLISHED
          ? _draft
          : _publish,
      ];
    } else if (content.getId.startsWith("card")) {
      actions = [
        _duplicate,
        _delete,
        content && content.contentState === ContentStatesEnum.PUBLISHED
          ? _draft
          : _publish,
      ];
    }

    return actions;
  }
}

export default ContentContextMenu;
